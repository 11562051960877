import {
    dictionary,
    locale,
    _
  } from 'svelte-i18n';
  
  function setupI18n({
    withLocale: _locale
  } = {
    withLocale: 'en'
  }) {
    dictionary.set({
      en: {
          "password": "Password",
          "password_recovery": "Password Recovery",
          "sign_in": "Sign In",
          "sign_up": "Sign Up",
          "do_sign_up": "Sign Up",
          "remember": "Remember me",
          "username":"Username",
          "login_error":"Incorrect e-mail or password",
          "field_maps":"Fields map",
          "welcome":"Welcome",
          "actions":"Actions",
          "add_new_field":"Add New Field",
          "field_name":"Field name",
          "save":"Save",
          "cancel":"Cancel",
          "logout":"Log Out",
          "user_id":"User ID",
          "delete":"Delete",
          "field_management":"Field management",
          "settings":"Settings",
          "lang":"Language",
          "vehicles_management":"Vehicles management",
          "my_vehicles":"My Vehicles",
          "vehicle_type":"Type",
          "vehicle_type_tractor":"Tractor",
          "vehicle_type_harvester":"Harvester",
          "vehicle_id":"Vehicle ID",
          "vehicle_name":"Vehicle Name",
          "edit":"Edit",
          "vra_prescription_maps":"VRA Prescription Maps",
          "select_vra_field":"Select field",
          "vra_map_name":"Prescription Map Name",
          "select_vra_files_iso_xml":"Select 2 prescription map files (.bin and .xml). Hold Ctrl key to select multiple files",
          "vra_maps":"Prescription maps for VRA"
      },

      ru: {
        "password": "Пароль",
        "password_recovery": "Восстановление пароля",
        "sign_in": "Войти",
        "remember": "Запомнить",
        "sign_up": "Регистрация",
        "do_sign_up": "Зарегистрироваться",
        "username":"Имя пользователя",
        "login_error":"Неверный логин или пароль",
        "field_maps":"Карта полей",
        "welcome":"Здравствуйте",
        "actions":"Действия",
        "add_new_field":"Добавить новое поле",
        "field_name":"Название поля",
        "Save":"Сохранить",
        "Cancel":"Отмена",
        "logout":"Выйти",
        "user_id":"Идентификатор пользователя",
        "delete":"Удалить",
        "field_management":"Управление полями",
        "settings":"Настройки",
        "lang":"Язык",
        "vehicles_management":"Управление техникой",
        "my_vehicles":"Моя сельскохозяйственная техника",
        "vehicle_type":"Тип техники",
        "vehicle_type_tractor":"Трактор",
        "vehicle_type_harvester":"Комбайн",
        "vehicle_id":"Числовой идентификатор",
        "vehicle_name":"Название техники",
        "edit":"Редактировать",
        "vra_prescription_maps":"Карты для диф.внесения",
        "select_vra_field":"Выберите поле",
        "vra_map_name":"Название карты предписаний",
        "select_vra_files_iso_xml":"Выберите 2 файла карты предписаний (.bin и .xml). Удерживайте клавишу Ctrl чтобы выбрать несколько файлов",
        "vra_maps":"Карты предписаний для диф. внесения",
        "Add New Field By Control Points":"Добавить новое поле по контрольным точкам",
        "Add point LAT,LON (ex. 50.12345,60.12345)":"Координаты точки поля в формате ШИРОТА,ДОЛГОТА (например 50.12345,60.12345)"
        

    },

 
    });
    locale.set(_locale);
  }
  export {
    _,
    setupI18n
  };