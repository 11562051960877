<script>
     import {myfields,myroutes, user_loged_in,sveltemap_global} from '../myfields_store.js'
     import { _ } from '../services/i18n';
     import {active_page2} from '../login_store.js'
     function setActivePage(page)
    {
       //active_page2.set(page); 
       let active_page = window.location.href.split(window.location.host)[1]
       if (active_page!='/#fields' && active_page!='/')
       {
        document.location="/#"+page;
        location.reload();
       }
    }
</script>

<div id="skin-select">
    <div id="logo">
        <a href="/"><h3 style='color:white; font-size:20px' >Tractor Pilot Connect
        </h3> </a>
    </div>

    <a id="toggle">
        <span class="entypo-menu"></span>
    </a>
    <div class="dark">
       
    </div>



    <div class="skin-part">
        <div id="tree-wrap">
            <div class="side-bar">
               <p style="color:white; font:14px Arial; padding-left:10px"><strong>
                   {$_('user_id')} {localStorage.getItem('client_code')}</strong></p>
                   <p style="color:white; font:14px Arial; padding-left:10px"><strong>
                    {$_('SYNC server port')} {localStorage.getItem('zmqPort')}</strong></p>
                <ul class="topnav menu-left-nest">

                   
                    <li>
                        <a class="tooltip-tip" href="/#fields" on:click="{() => setActivePage("fields")}">
                            <i class="icon-document-new"></i>
                            <span>{$_("field_management")}</span>
                        </a>
                        <ul>
                            {#each $myfields as fld, i}
                            <li style='border-top:1px solid #0DB8DF'>
                                <a class="tooltip-tip2 ajax-load" on:click="{$sveltemap_global.fldNameClicked(fld.id)}" 
                                style='cursor:pointer'>
                                    <i class="icon-map"></i><span>{fld.field_name}</span>
                                </a>

                                <a class="" on:click="{$sveltemap_global.delField(fld.id)}" style='cursor:pointer;'>
                                    <span style='color:#FF6B6B'>{$_("delete")}</span>
                                </a>
                         
                            </li>
                            {/each}
                            
                        </ul>
                    </li>
</ul>
<ul class="topnav menu-left-nest">
                    <li>
                        <a class="tooltip-tip" href="#vehicles" on:click="{() => setActivePage("vehicles")}">
                            <i class="icon-document-new"></i>
                            <span>{$_("vehicles_management")}</span>      
                        </a>
                    </li>
                </ul>


                <ul class="topnav menu-left-nest">
                    <li>
                        <a class="tooltip-tip" href="#vra_maps" on:click="{() => setActivePage("vra_maps")}">
                            <i class="icon-document-new"></i>
                            <span>{$_("vra_prescription_maps")}</span>      
                        </a>
                    </li>
                </ul>

  <!-- 
                    <li>
                        <a class="tooltip-tip ajax-load" href="#" title="Dashboard">
                            <i class="icon-window"></i>
                            <span>Маршруты обработки</span>
                            <ul>
                                {#each $myroutes as fld, i}
                            <li style='border-top:1px solid #0DB8DF'>
                                <a class="tooltip-tip2 ajax-load" on:click="{
                                    function(){
                                    $sveltemap_global.showFieldOnMap(fld.field_id);
                                    $sveltemap_global.getFldPathLines(fld.field_id,fld.routeName);
                                    }
                                }" style='cursor:pointer'>
                                    <i class="icon-map"></i><span>{fld.routeName} (поле: {fld.field_name})</span>
                                </a>

                                <a class="" on:click="{$sveltemap_global.delRoute(fld.field_id,fld.routeName)}" style='cursor:pointer;'>
                                    <span style='color:#FF6B6B'>Удалить</span>
                                </a>
                         
                            </li>
                            {/each}
                            </ul>

                        </a>
                    </li>

                  
                    <li>
                        <a class="tooltip-tip ajax-load" href="/vehicles" title="Mail">
                            <i class="icon-mail"></i>
                            <span>Управление техникой</span>
                            
                        </a>
                    </li>

                    <li>
                        <a class="tooltip-tip ajax-load" href="/reports" title="Icons">
                            <i class="icon-preview"></i>
                            <span>Отчёты</span>
                            
                        </a>
                    </li> -->

             

                  

         

             

                <!--
                <div class="side-dash">
                    <h3>
                        <span>Device</span>
                    </h3>
                    <ul class="side-dashh-list">
                        <li>Avg. Traffic
                            <span>25k<i style="color:#44BBC1;" class="fa fa-arrow-circle-up"></i>
                            </span>
                        </li>
                        <li>Visitors
                            <span>80%<i style="color:#AB6DB0;" class="fa fa-arrow-circle-down"></i>
                            </span>
                        </li>
                        <li>Convertion Rate
                            <span>13m<i style="color:#19A1F9;" class="fa fa-arrow-circle-up"></i>
                            </span>
                        </li>
                    </ul>

                 
                
                </div>
                -->

            </div>
        </div>
    </div>
</div>