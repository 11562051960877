import { writable } from 'svelte/store';

export const user_loged_in = writable(0);
export const active_page2 = writable(0);
export const tractor_position = writable({
    lat: 0.0,
    lon: 0.0,
    heading:0.0,
    vehicle_id:0,
    speed:0,
    jobProgress:0,
    fieldName:""
});
