<script>
      import { _ } from '../services/i18n';
      import { setupI18n } from '../services/i18n';
      //import {myisobuslogs} from '../myfields_store.js'
      import {myisobuslogs} from '../myfields_store.js'
      let api_host = 'https://tractorpilot.com'
     if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'
      let selected_lang = localStorage.getItem('userLang');
      const changeLang = () => {
          if (selected_lang!="")
          {
            localStorage.setItem('userLang',selected_lang);
            location.reload();
          }
	}

    async function getIsobusLogs() {
       let req = await fetch(api_host+"/Filer/getUserLogs/"+localStorage.getItem('client_code'));
       let data = await req.json();
        
       myisobuslogs.set(data)
    }

    getIsobusLogs();

</script>
<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_('settings')}.
                    </span>
                </h2>

            </div>

    
         
        </div>
    </div>
    <!--/ TITLE -->



<div class="content-wrap">
    <div class="row">


        <div class="col-sm-12">
       
            <h3>{$_("lang")}.</h3>
                <select bind:value={selected_lang} on:change={changeLang}>
                    <option value=""></option>
                    <option value="en">English</option>
                    <option value="ru">Russian</option>
                </select>

                </div>

    

                </div>

                </div>

                {#if $myisobuslogs && $myisobuslogs.length > 0}
                <div class="col-sm-12">    
                <div>
                    <h3>ISOBUS logs</h3>

                    <div class="body-nest" id="tableStatic">

                        <section id="flip-scroll">
            
                            <table class="table table-bordered table-striped cf">
                                <thead class="cf">
                                    <tr>
                                        <th>LOG file</th>
                                        <th></th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each $myisobuslogs as isolog, i}
                        <tr>
                            <td><a href="{api_host}/files/logs/{localStorage.getItem('client_code')}/{isolog.log_name}">{isolog.log_name}</a></td>
                            <td>{isolog.size} kb</td>
                            <td>&nbsp;</td>
                            </tr>

                                {/each}
                                </tbody>
                            </table>
                        </section>
            
                    </div>

                </div>
                </div>
                {/if}

                
            </div>
