<script>
    import {myfields, user_loged_in,myVRAmaps} from '../myfields_store.js'
    import { _ } from '../services/i18n';
    import { add } from "ol/coordinate";
    import { getContext } from 'svelte';
    //import {initTractorMarker} from '../Map.svelte'
    import {sveltemap_global} from '../myfields_store.js'
    let api_host = 'https://tractorpilot.com'
     if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'

     export async function getMyFields()
    {
       let my_fields_response = await fetch(api_host+"/Fields/userFieldsList/"+localStorage.getItem('client_code'));
       let my_fields_data = await my_fields_response.json();
       myfields.set(my_fields_data);
    }

    async function getMyVRAmaps() {
       let my_vramaps_req = await fetch(api_host+"/Fields/getVRAmaps/"+localStorage.getItem('client_code'));
       let my_vramaps_data = await my_vramaps_req.json();
       myVRAmaps.set(my_vramaps_data); 
    }

    async function delMap(mapId) {
        if (confirm("Are you sure?")) {
            let req = await fetch(api_host+"/Fields/delVRAmap/"+"/"+mapId+"/"+localStorage.getItem('client_code'));
            let res = await req.json();  
            getMyVRAmaps();  
        }
    }


  getMyFields();  
  getMyVRAmaps();
  let files;
  let selectedField=0;
  let vraMapName;


  const handleFileUpload = () => {
    let binSelected = false;
    let xmlSelected = false;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      let ext = files[i]["name"].split(".").slice(-1).toString().toLowerCase();
      //alert (ext);
      if (ext=="xml") xmlSelected = true;
      if (ext=="bin") binSelected = true;
      formData.append("files[]", files[i]); // Добавляем каждый файл в formData
    }

    if (xmlSelected && binSelected) {
        if (vraMapName!="" && xmlSelected>0) {
    fetch(api_host+"/Fields/saveVRAmap/"+localStorage.getItem('client_code')+"/"+selectedField+"/"+vraMapName, {
      method: "POST",
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.errors==0) {
            vraMapName = "";
            selectedField = 0;
            getMyVRAmaps();
      }  
      else {
          alert ("Upload Error "+data.errors);
      }
      console.log("File uploaded successfully", data);
    })
    .catch(error => {
      console.error("Error uploading file:", error);
    });
    }
    else {
        alert ("Some mandatory fields are empty!");
    }
}
else {
    alert ("Select .XML and .bin files!");
}
  };


</script>

<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_("vra_maps")}
                    </span>
                </h2>

            </div>

    
         
        </div>
    </div>


    <div class="nest" id="tableStaticClose">
        <div class="title-alt">
            <h6>
                {$_("vra_maps")}</h6>
            <div class="titleClose">
                <a class="gone" href="#tableStaticClose">
                    <span class="entypo-cancel"></span>
                </a>
            </div>
            <div class="titleToggle">
                <a class="nav-toggle-alt" href="#tableStatic">
                    <span class="entypo-up-open"></span>
                </a>
            </div>

        </div>

       </div>



       <div class="body-nest" id="tableStatic">

        <section id="flip-scroll">

            <table class="table table-bordered table-striped cf">
                <thead class="cf">
                    <tr>
                        <th>{$_("vra_map_name")}</th>
                        <th>{$_("field_name")}</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {#each $myVRAmaps as map, i}
                    <tr>
                        <td>{map.mapName}</td>
                        <td>{map.field}</td>
           
                        <td>
                            <a on:click={delMap(map.id)} style="cursor:pointer ;">{$_("delete")}</a> |
                        </td>
                    </tr> 
                    {/each}
                </tbody>
            </table>
        </section>

    </div>


       <div class="body-nest" id="validation">
        <div class="form_center">

            <form class="form-horizontal" on:submit|preventDefault={handleFileUpload}>
                <fieldset>
                    <div class="control-group">
                        <label class="control-label" for="name">{$_("select_vra_field")}</label>
                        <div class="controls">
                   <select class="form-control" bind:value='{selectedField}'>
                    {#each $myfields as fld, i}
                       <option>{fld.field_name}</option>
                    {/each}
                   </select>
                   </div>
                    </div>

                    <div class="control-group">
                        <label class="control-label" for="name">{$_("vra_map_name")}</label>
                        <div class="controls">
                            <input type="text" class="form-control" bind:value="{vraMapName}">
                   </div>
                    </div>

                    <div class="control-group">
                        <label class="control-label" for="name">{$_("select_vra_files_iso_xml")}</label>
                        <div class="controls">
                        <input type="file"  
                        multiple 
                        accept=".bin,.XML,.xml"
                        on:change={(e) => files = Array.from(e.target.files)}  />
                        </div>
                    </div>

           
                    <div class="form-actions" style="margin:20px 0 0 0;">
                        <button type="submit" class="btn btn-primary">{$_("Save")}</button>
                    </div>
                </fieldset>
            </form>

        </div>
    </div>



 
          
            
















</div>    