<script>
        import Modal from './Modal.svelte';
        import OpenlayersMap, {addInteraction,testFunc,showFieldOnMap, saveFldModal,} from '../Map.svelte'
        import {myfields, user_loged_in,sveltemap_global} from '../myfields_store.js'
        import { _ } from '../services/i18n';
</script>

{#if $sveltemap_global.showModal}
<Modal on:close="{() => showModal = false}">
    <div class="body-nest" id="save_fld_modal">
      <div class="form_center">
    
          <form action="/#fields" id="contact-form" class="form-horizontal" novalidate="novalidate">
              <fieldset>
    
    
                  <div class="control-group">
                      <label class="control-label" for="name">Название поля</label>
                      <div class="controls">
                          <input type="text" class="form-control" name="name" id="name">
                      </div>
                  </div>
             
                 
                  <div class="form-actions" style="margin:20px 0 0 0;">
                      <button type="submit" class="btn btn-primary">Сохранить</button>
                      <button type="reset" class="btn" on:click={close}>Отмена</button>
                  </div>
              </fieldset>
          </form>
    
      </div>
    </div>
    </Modal>
    {/if}