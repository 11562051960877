<script>
  import {user_loged_in} from '../login_store.js'   
  import { _ } from '../services/i18n';

  let api_host = 'https://tractorpilot.com'
  if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'
  let isSubmitting = false;
  let user_email = '';
  let user_password = '';
  let user_name = '';
  let signup_errors={'email':'','username':'','password':''};
 
async function signup()
{
  let signup_response = await fetch(api_host+"/Users/reg_user/", {
  body: "username="+user_name+"&email="+user_email+"&password="+user_password,
  headers: {"Content-Type": "application/x-www-form-urlencoded"},
  method: "post",
});
   let signup_response_data = await signup_response.json();
   if (signup_response_data.success>0)
   {
        user_loged_in.set(signup_response_data.token);
        localStorage.setItem('token', signup_response_data.token);
        localStorage.setItem('client_code', signup_response_data.client_code);
        localStorage.setItem('client_name', signup_response_data.client_name);
        localStorage.setItem('zmqPort',signup_response_data.zmqPort);
        document.location = '/';
   }
   else
   {
    signup_errors = signup_response_data.errors;
    for (const [key, err_text] of Object.entries(signup_response_data.errors)) {
        document.getElementById(key+"_err").innerHTML = err_text;
    }
  }
   
}

function submit() {
    isSubmitting = true;
    signup()
    setTimeout(() => {
      isSubmitting = false;
    }, 2000);
  }

</script>

<div class="container">

    <div class="" id="login-wrapper">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <div id="logo-login">
                    <h3>Tractor Pilot Connect
                    </h3>
                </div>
            </div>
  
        </div>
  
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <form on:submit|preventDefault={submit} role="form">
                <div class="account-box">
  
                   
                        <div class="form-group">
                            <a href="#" class="pull-right label-forgot"></a>
                            <label for="inputUsername">{$_('username')}</label>
                            <input type="text" id="inputUsername" class="form-control" bind:value="{user_name}">
                            <span style='color:red' id='username_err'></span>
                        </div>

                        <div class="form-group">
                            <a href="#" class="pull-right label-forgot"></a>
                            <label for="inputUserEmail">E-Mail</label>
                            <input type="email" id="inputUserEmail" class="form-control" bind:value="{user_email}">
                            <span style='color:red' id='email_err'></span>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword">{$_('password')}</label>
                            <input type="password" id="inputPassword" class="form-control" bind:value="{user_password}">
                            <span style='color:red' id='password_err'></span>
                        </div>
                       
                    <hr>
                    <div class="row-block">
                        <div class="row">
                            <div class="col-md-12 row-block">
                                <button class="btn btn-primary btn-block" type='submit'>{$_("do_sign_up")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            </div>
        </div>
    </div>
  
  
  
    <div style="text-align:center;margin:0 auto;">
        <h6 style="color:#fff;"><a style='color:white' href=#></a></h6>
    </div>
  
  </div>
  
  