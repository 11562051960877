<script>
  import {user_loged_in} from '../login_store.js'
  import { _ } from '../services/i18n';
  export let login_error_visibility = "none";  
  let isSubmitting = false;
  let user_email = '';
  let user_password = '';
  let api_host = 'https://tractorpilot.com'
  if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'


  async function auth()
  {
    let login_response = await fetch(api_host+"/Users/auth/", {
    body: "user_email="+user_email+"&user_password="+user_password,
    headers: {"Content-Type": "application/x-www-form-urlencoded"},
    method: "post",
  });
     let login_response_data = await login_response.json();
     if (login_response_data.status=='ok')
     {
        user_loged_in.set(login_response_data.token);
        console.log(login_response_data);
        localStorage.setItem('token', login_response_data.token);
        localStorage.setItem('client_code', login_response_data.client_code);
        localStorage.setItem('client_name', login_response_data.client_name);
        localStorage.setItem('zmqPort',login_response_data.zmqPort);

        document.location = '/';
     }
     else{
        login_error_visibility = "block";
     }

    
     //alert (user_loged_in)
     //alert (login_response_data.status);  
  }

  function submit() {
    isSubmitting = true;
    auth()
    setTimeout(() => {
      isSubmitting = false;
    }, 2000);
  }
  
</script>


<div class="container">

  <div class="" id="login-wrapper">
      <div class="row">
          <div class="col-md-4 col-md-offset-4">
              <div id="logo-login">
                  <h3>Tractor Pilot Connect
                  </h3>
              </div>
          </div>

      </div>

      <div class="row">
          <div class="col-md-4 col-md-offset-4">
              <div class="account-box">

                  <form on:submit|preventDefault={submit} role="form">
                      <div class="form-group">
                          <a href="#" class="pull-right label-forgot"></a>
                          <label for="inputUsernameEmail">E-Mail</label>
                          <input type="text" id="inputUsernameEmail" class="form-control" bind:value="{user_email}">
                      </div>
                      <div class="form-group">
                          <a href="#" class="pull-right label-forgot">{$_('password_recovery')}</a>
                          <label for="inputPassword">{$_('password')}</label>
                          <input type="password" id="inputPassword" class="form-control" bind:value="{user_password}">
                      </div>
                      <div class="form-group">
                        <span style="color:red; display:{login_error_visibility}">{$_('login_error')}</span>
                      </div>
                      <div class="checkbox pull-left">
                          <label>
                              <input type="checkbox" checked>{$_('remember')}</label>
                      </div>
                      <button class="btn btn btn-primary pull-right" type="submit">
                          {$_('sign_in')}
                      </button>
                  </form>
                 
                  <div class="or-box">
                    
        
                  </div>
                  <hr>
                  <div class="row-block">
                      <div class="row">
                          <div class="col-md-12 row-block">
                              <a href="/signup" class="" >{$_('sign_up')}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>





  <div style="text-align:center;margin:0 auto;">
    <h6 style="color:#fff;"><a style='color:white' href=#></a></h6>
  </div>

</div>


