<script>
    import { _ } from '../services/i18n';
    import CenterPart from './CenterPart.svelte';
   // import {active_page} from CenterPart
    import {active_page2} from '../login_store.js'
    function logOut()
    {
        localStorage.removeItem('token');
        document.location='/';
    }
    function setActivePage(page)
    {
       //active_page2.set(page); 
       document.location="/#"+page;
       location.reload();
    }
</script>
<nav role="navigation" class="navbar navbar-static-top">
    <div class="container-fluid">

        <div class="navbar-header">
            <button data-target="#bs-example-navbar-collapse-1" data-toggle="collapse" class="navbar-toggle" type="button">
                <span class="entypo-menu"></span>
            </button>
            <button class="navbar-toggle toggle-menu-mobile toggle-left" type="button">
                <span class="entypo-list-add"></span>
            </button>


            <div id="logo-mobile" class="visible-xs">
                <h1>Tractor Pilot Online
                    
                </h1>
            </div>

        </div>


        <div id="bs-example-navbar-collapse-1" class="collapse navbar-collapse">
            
            <ul class="nav navbar-nav">

                <li class="dropdown">

                    <!-- <a data-toggle="dropdown" class="dropdown-toggle" href="#"><i style="font-size:20px;" class="icon-conversation"></i>
                        <div class="noft-red">23</div></a> -->

                </li>
                <li>
                      <!--   
                    <a data-toggle="dropdown" class="dropdown-toggle" href="#"><i style="font-size:19px;" class="icon-warning tooltitle"></i>
                        <div class="noft-green">5</div></a> -->
                  
                </li>
          

            </ul> 
            <!--
            <div id="nt-title-container" class="navbar-left running-text visible-lg">
                <ul class="date-top">
                    <li class="entypo-calendar" style="margin-right:5px"></li>
                    <li id="Date"></li>


                </ul>

                <ul id="digital-clock" class="digital">
                    <li class="entypo-clock" style="margin-right:5px"></li>
                    <li class="hour"></li>
                    <li>:</li>
                    <li class="min"></li>
                    <li>:</li>
                    <li class="sec"></li>
                    <li class="meridiem"></li>
                </ul>
                <ul id="nt-title">
                    <li><i class="wi-day-lightning"></i>&#160;&#160;Berlin&#160;
                        <b>85</b><i class="wi-fahrenheit"></i>&#160;; 15km/h
                    </li>
                    

                </ul>
            </div> -->

            <ul style="margin-right:0;" class="nav navbar-nav navbar-right">
                <li>
                    <a data-toggle="dropdown" class="dropdown-toggle" href="#">
                        <img alt="" class="admin-pic img-circle" src="https://tractorpilot.com/images/user_default.png">
                        {localStorage.getItem('client_name')} <b class="caret"></b>
                    </a>
                    <ul style="margin-top:14px;" role="menu" class="dropdown-setting dropdown-menu">
                        <!-- <li>
                            <a href="#">
                                <span class="entypo-vcard"></span>&#160;&#160;Настройки профиля</a>
                        </li> -->
                     
                        <li class="divider"></li>
                        <li>
                            <a href="#" on:click={logOut}>
                                <span class="entypo-logout"></span>&#160;&#160;{$_("Log Out")}</a>
                        </li>
                    </ul>
                </li>

                <li>
                    <a href=#settings on:click="{() => setActivePage("settings")}">
                        <span class="icon-gear"></span>&#160;&#160;{$_('settings')}</a>
               
                </li>
             
                <li class="hidden-xs">
                    <a class="toggle-left" href="#">
                        <span style="font-size:20px;" class="entypo-list-add"></span>
                    </a>
                </li>
            </ul>

        </div>
        <!-- /.navbar-collapse -->
    </div>
    <!-- /.container-fluid -->
</nav>