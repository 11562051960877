<script>
	import {user_loged_in, tractor_position} from './login_store.js'
	
	import Sidebar from './components/Sidebar.svelte';
	import SidebarRight from './components/SidebarRight.svelte';

	import CenterPart from './components/CenterPart.svelte';
	import Headernavi from './components/Headernavi.svelte';
	import Footer from './components/Footer.svelte';
	import Auth from './components/Auth.svelte';
	import AllModals from './components/AllModals.svelte';
    import Signup from './components/Signup.svelte';

	import { setupI18n } from './services/i18n';
    import { hasActiveVehicles } from './myfields_store.js';

	var locale = localStorage.getItem('userLang');
	if (locale!=null)
	{
		if (locale.length<2) locale="en";
	}
	else locale = "en";
	setupI18n({ withLocale: locale });
	
	if (localStorage.getItem('token')) user_loged_in.set(localStorage.getItem('token'));

	let w_socket_url = "wss://connect.tractorpilot.com:4277";
	if (location.host.includes("localhost"))  w_socket_url = 'ws://tp.localhost:4277'
	let w_socket = new WebSocket(w_socket_url);
	w_socket.onopen = function(e) {
			//alert("[open] Соединение установлено");
			//let report_id_msg = {'cmd':1, 'tp_cloud_user_id':'1234567890'};
			//w_socket.send(JSON.stringify(report_id_msg));
	};

	 w_socket.onmessage = function(event) {
     //alert (event.data);
	 let msgFromServer = JSON.parse(event.data);
	 let msgType = msgFromServer.response_type;
	 let socket_id = msgFromServer.socket_id;	
	 switch (msgType)
	 {	
		case 1:
			w_socket.send(JSON.stringify({'cmd':1, 'tp_cloud_user_id':localStorage.getItem('client_code'),'socket_id':socket_id}));
		break;

		case 10:
			//console.log(msgFromServer);
			hasActiveVehicles.set(true);
			tractor_position.set(
				{
				lon:msgFromServer.lon, 
				lat: msgFromServer.lat,
				heading:msgFromServer.heading, 
				vehicle_id:msgFromServer.tp_device_id,
				speed:msgFromServer.speed,
				jobProgress:msgFromServer.jobPercent,
				fieldName:msgFromServer.fieldName
			} 
				)
			break;
	 }
	 //alert(msgType);
	};

let active_page = window.location.href.split(window.location.host)[1];
//alert (active_page)
</script>

{#if active_page!="/signup"}

{#if $user_loged_in}
<Headernavi />
<Sidebar />
<CenterPart active_page={active_page} />
<SidebarRight />
<AllModals />

{:else}
<Auth/>
{/if}

{:else}
<Signup/>
{/if}





